// Function
import 'core-js/es/function/bind';
import 'core-js/es/function/name';
// Number
import 'core-js/es/number/parse-float';
import 'core-js/es/number/parse-int';
// Array
import 'core-js/es/array/filter';
import 'core-js/es/array/find';
import 'core-js/es/array/is-array';
import 'core-js/es/array/join';
import 'core-js/es/array/map';
import 'core-js/es/array/slice';
import 'core-js/es/array/sort';
import 'core-js/es/array/splice';
// Symbol
import 'core-js/es/symbol';

// import 'core-js/es/object';
// import 'core-js/es/function';
// import 'core-js/es/number';
// import 'core-js/es/math';
// import 'core-js/es/string';
// import 'core-js/es/date';
// import 'core-js/es/array';
// import 'core-js/es/regexp';
// import 'core-js/es/map';
// import 'core-js/es/weak-map';
// import 'core-js/es/set';
// import 'core-js/es/reflect';
// import 'core-js/web/dom-collections';
